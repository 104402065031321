<template>
  <b-modal
    id="received-products-modal"
    ref="received-products-modal"
    title="Confirmar orden de traspaso"
    centered
    size="xl"
    @hidden="onCloseModal"
  >
    <b-card-text>

      <!-- #region::Products and circuits list -->
      <b-row>

        <b-col md="12">
          Selecciona los productos que llegaron
        </b-col>

        <!-- #region begin::Searcher -->
        <b-col
          class="my-2"
          md="4"
        >
          <BasicSearcher
            :callback="handleSearch"
            placeholder="Buscar productos y circuitos"
          />
        </b-col>
        <!-- #endregion end::Searcher -->

        <b-col md="12">
          <TransferProducts
            :selectable="true"
            :can-be-filtered="true"
            :show-edit-button="false"
            :show-delete-button="false"
            :show-add-product-button="false"
            :show-pieces-list-actions-buttons="false"
          />
        </b-col>

        <b-col
          class="mt-2"
          md="12"
        >
          <TransferCircuits
            :selectable="true"
            :can-be-filtered="true"
            :show-delete-button="false"
            :show-add-circuit-button="false"
          />
        </b-col>

      </b-row>
      <!-- #endregion::Products and circuits list -->

    </b-card-text>

    <!-- #region::Footer -->
    <template #modal-footer>
      <b-button
        variant="delete-btn"
        class="delete-btn"
        @click="hideModal"
      >
        Cancelar
      </b-button>
      <b-button
        variant="principal-btn"
        class="principal-btn"
        :disabled="receivingPieces"
        @click="onReceivePieces"
      >
        <b-spinner
          v-if="receivingPieces"
          class="mr-50"
          small
        />
        <span class="align-middle">Confirmar orden</span>
      </b-button>
    </template>
    <!-- #endregion::Footer -->
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, VBModal, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'

import BasicSearcher from '@/components/forms/BasicSearcher.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCardText,
    BasicSearcher,
    TransferProducts: () => import('@/modules/production/transfers/components/form/TransferProducts.vue'),
    TransferCircuits: () => import('@/modules/production/transfers/components/form/TransferCircuits.vue'),
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    transferId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      receivingPieces: false,
    }
  },
  computed: {
    ...mapGetters({
      getTransfer: 'transfers/getTransfer',
      getTransferCircuits: 'transfers/getTransferCircuits',
      getReceivedProvitionalCircuits: 'transfers/getReceivedProvitionalCircuits',
      getReceivedProvitionalProductsPieces: 'transfers/getReceivedProvitionalProductsPieces',
    }),
    transfer: {
      get() { return this.getTransfer.transfer },
    },
    transferCircuits: {
      get() { return this.getTransferCircuits },
    },
    receivedProvitionalCircuits: {
      get() { return this.getReceivedProvitionalCircuits },
    },
    receivedProvitionalProductsPieces: {
      get() { return this.getReceivedProvitionalProductsPieces },
    },
  },
  methods: {
    ...mapActions({
      setSearchTerm: 'transfers/setSearchTerm',
      receivedTransfer: 'transfers/receivedTransfer',
      clearDeliveryState: 'transfers/clearDeliveryState',
    }),
    showModal() {
      this.$refs['received-products-modal'].show()
    },
    hideModal() {
      this.$refs['received-products-modal'].hide()
    },
    async onReceivePieces() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        const formData = new FormData()

        formData.append('id', this.transfer.IdTransfer)

        this.getReceivedProvitionalProductsPieces.forEach(piece => {
          formData.append('pieces[]', JSON.stringify({ idPiece: piece.IdPiece }))
        })

        this.receivedProvitionalCircuits.forEach(circuit => {
          circuit.products.forEach(product => {
            product.pieces.forEach(piece => {
              formData.append('pieces[]', JSON.stringify({ idPiece: piece.IdPiece }))
            })
          })
        })

        // eslint-disable-next-line no-restricted-syntax
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }

        await this.receivedTransfer(formData)
        this.$swal.close()

        this.showToast(
          'Orden recibida',
          'La orden de traspaso se estableció como recibida correctamente.',
          'success',
        )

        this.$emit('reload-transfer')
        this.hideModal()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.receivingPieces = false
      }
    },
    handleSearch(searchTerm) {
      this.setSearchTerm(searchTerm)
    },
    onCloseModal() {
      this.transferCircuits.forEach(transferCircuit => {
        const circuit = transferCircuit
        circuit.selected = false
      })

      this.clearDeliveryState()
      this.$emit('on-close-modal')
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
