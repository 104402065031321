<template>
  <div>

    <b-form-row align-v="center">

      <!-- #region::Return to transfers list link -->
      <b-col
        cols="12"
        class="px-0 mb-1"
      >
        <small>
          <router-link
            :to="{ name: 'transfers-list' }"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              class="mr-50 text-secondary"
            />
            <span class="align-middle text-secondary">Regresar</span>
          </router-link>
        </small>
      </b-col>
      <!-- #endregion::Return to transfers list link -->

      <!-- #region::Title with tag -->
      <b-col>
        <div class="d-flex justify-content-left">
          <h3 class="align-self-center">
            Traspaso #{{ transfer.ID }}
          </h3>
          <div
            :class="[statusBorderStyle, statusColorStyle]"
            role="alert"
          >
            {{ transfer.StatusText }}
          </div>
          <div>
            <OrderModalHistory
              v-if="historyStatus"
              :history="historyStatus"
            />
          </div>
        </div>
      </b-col>
      <!-- #endregion::Title with tag -->

      <!-- #region::Edit transfer button -->
      <b-col
        v-if="canEditTransfer && canManageTransfer"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="my-1 my-lg-0 principal-btn"
          @click="editionModeState = true"
        >
          <feather-icon
            icon="Edit3Icon"
            class="mr-50"
          />
          <span class="align-middle">Editar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Edit transfer button -->

      <!-- #region::Confirm transfer button -->
      <b-col
        v-if="canConfirmTransfer && canManageTransfer"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="my-1 my-lg-0 principal-btn"
          :disabled="confirmingTransfer"
          @click="onConfirmTransfer"
        >
          <b-spinner
            v-if="confirmingTransfer"
            class="mr-50"
            small
          />
          <span class="align-middle">Confirmar traspaso</span>
        </b-button>
      </b-col>
      <!-- #endregion::Confirm transfer button -->

      <!-- #region::Cancel transfer button -->
      <b-col
        v-if="canCancelTransfer && canManageTransfer"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          :disabled="cancelingTransfer"
          @click="onCancelTransfer"
        >
          <b-spinner
            v-if="cancelingTransfer"
            class="mr-50"
            small
          />
          <span class="align-middle">Cancelar traspaso</span>
        </b-button>
      </b-col>
      <!-- #endregion::Cancel transfer button -->

      <!-- #region::Approve transfer button -->
      <b-col
        v-if="canApproveTransfer"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="my-1 my-lg-0 principal-btn"
          :disabled="approvingTransfer"
          @click="onApproveTransfer"
        >
          <b-spinner
            v-if="approvingTransfer"
            class="mr-50"
            small
          />
          <span class="align-middle">Aprobar traspaso</span>
        </b-button>
      </b-col>
      <!-- #endregion::Approve transfer button -->

      <!-- #region::Reject transfer button -->
      <b-col
        v-if="canRejectTransfer"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          :disabled="rejectingTransfer"
          @click="onRejectTransfer"
        >
          <b-spinner
            v-if="rejectingTransfer"
            class="mr-50"
            small
          />
          <span class="align-middle">Rechazar traspaso</span>
        </b-button>
      </b-col>
      <!-- #endregion::Reject transfer button -->

      <!-- #region::Ready for delivery transfer button -->
      <b-col
        v-if="canMakeForDeliveryTransfer"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="my-1 my-lg-0 principal-btn"
          :disabled="makingForDeliveryTransfer"
          @click="onMakingForDeliveryTransfer"
        >
          <b-spinner
            v-if="makingForDeliveryTransfer"
            class="mr-50"
            small
          />
          <span class="align-middle">Lista para traspaso</span>
        </b-button>
      </b-col>
      <!-- #endregion::Ready for delivery transfer button -->

      <!-- #region::Ready for delivery transfer button -->
      <b-col
        v-if="canMakeInTransitTransfer"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="my-1 my-lg-0 principal-btn"
          :disabled="makingInTransitTransfer"
          @click="onMakingInTransitTransfer"
        >
          <b-spinner
            v-if="makingInTransitTransfer"
            class="mr-50"
            small
          />
          <span class="align-middle">Salida de bodega</span>
        </b-button>
      </b-col>
      <!-- #endregion::Ready for delivery transfer button -->

      <!-- #region::Received transfer button -->
      <b-col
        v-if="canReceiveTransfer"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="my-1 my-lg-0 principal-btn"
          :disabled="receivingTransfer"
          @click="onReceiveTransfer"
        >
          <b-spinner
            v-if="receivingTransfer"
            class="mr-50"
            small
          />
          <span class="align-middle">Confirmar orden</span>
        </b-button>
      </b-col>
      <!-- #endregion::Received transfer button -->

      <!-- #region::Reject transfer button -->
      <b-col
        v-if="canDeleteTransfer && canManageTransfer"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          :disabled="deletingTransfer"
          @click="onDeleteTransfer"
        >
          <b-spinner
            v-if="deletingTransfer"
            class="mr-50"
            small
          />
          <span class="align-middle">Eliminar traspaso</span>
        </b-button>
      </b-col>
      <!-- #endregion::Reject transfer button -->

      <!-- #region::Download pdf button -->
      <b-col
        v-if="canDownloadPdf"
        cols="12"
        md="auto"
      >
        <b-button
          variant="flat-success"
          class="csv-btn"
          :disabled="downloadingFile"
          @click="onDownloadPDF"
        >
          <b-spinner
            v-if="downloadingFile"
            class="mr-50"
            small
          />
          <feather-icon
            v-if="!downloadingFile"
            icon="DownloadIcon"
            class="mr-50"
          />
          <span class="align-middle">Descargar PDF</span>
        </b-button>
      </b-col>
      <!-- #endregion::Download pdf button -->

      <!-- #region::Save changes button -->
      <b-col
        v-if="editionModeState === true"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          @click="callback"
        >
          Guardar cambios
        </b-button>
      </b-col>
      <!-- #endregion::Save changes button -->

      <!-- #region::Cancel update transfer button -->
      <b-col
        v-if="editionModeState === true"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="onCancelingTransferUpdate"
        >
          Cancelar
        </b-button>
      </b-col>
      <!-- #endregion::Cancel update transfer button -->

    </b-form-row>

    <!-- #region::Modal for making rejected transfer comment -->
    <TransferRejectingCommentModal
      v-if="$ability.can('reject', 'Transfer')"
      ref="transferRejectingCommentModal"
      @reload-transfer="$emit('reload-transfer')"
    />
    <!-- #endregion::Modal for making rejected transfer comment -->

    <!-- #region::Modal for making received pieces -->
    <TransferReceivedProductsModal
      ref="transferReceivedProductsModal"
      :transfer-id="transfer.IdTransfer"
      @reload-transfer="$emit('reload-transfer')"
    />
    <!-- #endregion::Modal for making received pieces -->

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BCol, BButton, BFormRow, BSpinner,
} from 'bootstrap-vue'

import OrderModalHistory from '@/modules/trade/orders/components/modals/OrderModalHistory.vue'
import TransferRejectingCommentModal from '@/modules/production/transfers/components/modals/TransferRejectingCommentModal.vue'
import TransferReceivedProductsModal from '@/modules/production/transfers/components/modals/TransferReceivedProductsModal.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BCol,
    BButton,
    BSpinner,
    BFormRow,
    OrderModalHistory,
    TransferRejectingCommentModal,
    TransferReceivedProductsModal,
  },
  props: {
    callback: {
      type: Function,
      required: true,
    },
    canManageTransfer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      historyStatus: null,
      downloadingFile: false,
      deletingTransfer: false,
      approvingTransfer: false,
      cancelingTransfer: false,
      rejectingTransfer: false,
      receivingTransfer: false,
      confirmingTransfer: false,
      makingInTransitTransfer: false,
      makingForDeliveryTransfer: false,

      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    ...mapGetters({
      getTransfer: 'transfers/getTransfer',
      getTransferPieces: 'transfers/getTransferPieces',
      getSelectedOrigin: 'transfers/getSelectedOrigin',
      getTransferCircuits: 'transfers/getTransferCircuits',
      getEditionModeState: 'transfers/getEditionModeState',
    }),
    /**
     * Información de la transferencia
     */
    transfer: {
      get() { return this.getTransfer.transfer },
    },
    /**
     * Circuitos de la transferencia
     */
    circuits: {
      get() { return this.getTransferCircuits },
    },
    /**
     * Piezas de los productos de la transferencia
     */
    pieces: {
      get() { return this.getTransferPieces },
    },
    /**
     * Origen del traspaso seleccionado
     */
    selectedOrigin: {
      get() { return this.getSelectedOrigin },
    },
    /**
     * Piezas de los circuitos de la transferencia
     */
    circuitPieces: {
      get() {
        const pieces = []

        this.circuits.forEach(circuit => {
          circuit.products.forEach(product => {
            product.pieces.forEach(piece => {
              pieces.push(piece)
            })
          })
        })

        return pieces
      },
    },
    /**
     * Verifica si existen piezas con estado "No disponible" de circuitos y productos
     */
    unavailablePieces: {
      get() {
        const isUnavailablePieces = piece => piece.Status === 0
        const unavailableCircuitsPieces = this.circuitPieces.some(isUnavailablePieces)
        const unavailablePieces = this.pieces.some(isUnavailablePieces)

        return unavailableCircuitsPieces || unavailablePieces
      },
    },
    /**
     * Vefifica si existen piezas en la orden de traspaso
     */
    piecesInTransfer: {
      get() {
        return this.pieces.length || this.circuitPieces.length
      },
    },
    /**
     * Verifica que todas las piezas tengan la misma ubicación
     */
    piecesWithSameLocation: {
      get() {
        // Se obtiene la ubicación a partir de las piezas
        const location = this.pieces.length ? this.pieces[0].Location : this.circuitPieces[0].Location

        // Se valida si todas las piezas pertenecen a la misma ubicación
        const isSameLocation = piece => piece.Location === location
        const sameLocationCircuitsPieces = this.circuitPieces.every(isSameLocation)
        const sameLocationPieces = this.pieces.every(isSameLocation)

        return sameLocationCircuitsPieces && sameLocationPieces
      },
    },
    /**
     * Verifica que todas las piezas tengan la misma ubicación que la ubicación de origen
     * de la orden de traspaso
     */
    piecesWithSameOriginLocation: {
      get() {
        // Se valida si todas las piezas pertenecen a la misma ubicación de origen del traspaso
        const isSameLocation = piece => piece.Location === this.transfer.LocationOrigin
        const sameLocationCircuitsPieces = this.circuitPieces.every(isSameLocation)
        const sameLocationPieces = this.pieces.every(isSameLocation)

        return sameLocationCircuitsPieces && sameLocationPieces
      },
    },
    /**
     * Valida si una o mós piezas de la orden de traspaso se encuentra en otra orden
     * de traspaso.
     */
    piecesWithTransferTag: {
      get() {
        const isInTransfer = piece => piece.Transfer === 'Si'
        const inTransferProductPieces = this.pieces.some(isInTransfer)
        const inTransferCircuitPieces = this.circuitPieces.some(isInTransfer)

        return inTransferProductPieces || inTransferCircuitPieces
      },
    },
    /**
     * Verifica si la transferencia está en modo edición
     */
    editionModeState: {
      get() { return this.getEditionModeState },
      set(value) { this.setEditionModeState(value) },
    },
    statusBorderStyle() {
      switch (this.transfer.StatusText) {
        case 'Orden':
          return 'banner-alert-bordered'
        default:
          return 'banner-alert'
      }
    },
    statusColorStyle() {
      switch (this.transfer.StatusText) {
        case 'Solicitud':
          return 'alert-purple'
        case 'Traspaso':
          return 'alert-pink'
        case 'Aprobada':
          return 'alert-green'
        case 'Rechazada':
          return 'alert-orange'
        case 'En transito':
          return 'alert-yellow-black'
        case 'Listo para traspaso':
          return 'alert-marine'
        case 'Recibida':
          return 'alert-blue'
        default:
          return 'alert-gray'
      }
    },
    canEditTransfer() {
      const transferStatus = this.transfer.StatusText
      const statuses = ['Solicitud', 'Traspaso', 'Aprobada', 'Rechazada']

      return this.editionModeState === false
      && this.$ability.can('edit', 'Transfer')
      && statuses.includes(transferStatus)
    },
    canConfirmTransfer() {
      const transferStatus = this.transfer.StatusText
      const statuses = ['Traspaso', 'Rechazada']

      return this.editionModeState === false
        && this.$ability.can('confirm', 'Transfer')
        && statuses.includes(transferStatus)
    },
    canCancelTransfer() {
      const transferStatus = this.transfer.StatusText
      const statuses = ['Traspaso', 'Solicitud', 'Aprobada', 'Rechazada', 'Listo para traspaso']

      return this.editionModeState === false
        && this.$ability.can('cancel', 'Transfer')
        && statuses.includes(transferStatus)
    },
    canApproveTransfer() {
      return this.$ability.can('approve', 'Transfer')
        && this.transfer.StatusText === 'Solicitud'
        && this.transfer.LocationOrigin === this.userData.Location
    },
    canRejectTransfer() {
      return this.$ability.can('reject', 'Transfer')
        && this.transfer.StatusText === 'Solicitud'
        && this.transfer.LocationOrigin === this.userData.Location
    },
    canMakeForDeliveryTransfer() {
      return this.$ability.can('confirm ready', 'Transfer')
        && this.transfer.StatusText === 'Aprobada'
        && this.transfer.LocationOrigin === this.userData.Location
    },
    canMakeInTransitTransfer() {
      return this.$ability.can('confirm in transit', 'Transfer')
        && this.transfer.StatusText === 'Listo para traspaso'
        && this.transfer.LocationOrigin === this.userData.Location
    },
    canReceiveTransfer() {
      return this.$ability.can('confirm received', 'Transfer')
        && this.transfer.StatusText === 'En transito'
        && this.transfer.LocationDestiny === this.userData.Location
    },
    canDeleteTransfer() {
      const statuses = ['Recibida', 'Cancelado']

      return this.$ability.can('delete', 'Transfer')
        && statuses.includes(this.transfer.StatusText)
    },
    canDownloadPdf() {
      return this.editionModeState === false
        && this.$ability.can('download pdf', 'Transfer_pdf')
    },
  },
  watch: {
    transfer() {
      this.updateStatusTrasnfer()
    },
  },
  beforeDestroy() {
    this.clearFormData()
  },
  async created() {
    await this.updateStatusTrasnfer()
  },
  methods: {
    ...mapActions({
      clearFormData: 'transfers/clearFormData',
      deleteTransfer: 'transfers/deleteTransfer',
      cancelTransfer: 'transfers/cancelTransfer',
      confirmTransfer: 'transfers/confirmTransfer',
      downloadPDFFile: 'transfers/downloadPDFFile',
      approveTransfer: 'transfers/approveTransfer',
      receivedTransfer: 'transfers/receivedTransfer',
      loadStatusTransfer: 'transfers/loadStatusTransfer',
      setEditionModeState: 'transfers/setEditionModeState',
      makeInTransitTransfer: 'transfers/makeInTransitTransfer',
      makeForDeliveryTransfer: 'transfers/makeForDeliveryTransfer',
    }),

    async updateStatusTrasnfer() {
      const response = await this.loadStatusTransfer(this.transfer.IdTransfer)
      this.historyStatus = response.data.data
    },

    /**
    * Confirmación de la orden de traspaso
    *
    * @summary Verifica la disponibilidad de las piezas y la ubicación. Si las
    * validaciones son positivas, la orden de traspaso será confirmada
    * @exception ⁡⁢⁣⁢Error de validación en la confirmación del traspaso
    */
    async onConfirmTransfer() {
      // Valida que las piezas se encuentren disponibles
      if (this.unavailablePieces) {
        this.showToast(
          'Error de validación',
          'Una o más piezas no están disponibles.',
          'warning',
        )
        return
      }

      if (this.piecesWithTransferTag) {
        this.showToast(
          'Error de validación',
          'Una o más piezas se encuentran en una orden de traspaso.',
          'warning',
        )
        return
      }

      // Verifica la ubicación de las piezas
      if (!this.validLocationPieces()) {
        return
      }

      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.confirmingTransfer = true

        const formData = new FormData()
        formData.append('id', this.transfer.IdTransfer)
        await this.confirmTransfer(formData)
        this.$swal.close()

        this.showToast(
          'Orden confirmada',
          'La orden de traspaso ha sido confirmada correctamente.',
          'success',
        )

        this.$emit('reload-transfer')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.confirmingTransfer = false
      }
    },

    /**
    * Cancelación de la orden de traspaso
    *
    * @summary Verifica la disponibilidad de las piezas. Si las
    * validaciones son positivas, la orden de traspaso será cancelada
    * @exception ⁡⁢⁣⁢Error de validación en la cancelación del traspaso
    */
    async onCancelTransfer() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.cancelingTransfer = true

        const formData = new FormData()
        formData.append('id', this.transfer.IdTransfer)

        await this.cancelTransfer(formData)
        this.$swal.close()

        this.showToast(
          'Orden cancelada',
          'La orden de traspaso ha sido cancelada correctamente.',
          'success',
        )

        this.$emit('reload-transfer')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.cancelingTransfer = false
      }
    },

    /**
    * Aprobación de la orden de traspaso
    *
    * @summary Verifica la disponibilidad de las piezas y la ubicación. Si las
    * validaciones son positivas, la orden de traspaso será aprobada
    * @exception ⁡⁢⁣⁢Error de validación en la aprobación del traspaso
    */
    async onApproveTransfer() {
      try {
        // Verifica la ubicación de las piezas
        if (!this.validLocationPieces()) {
          return
        }

        if (!this.transfer.DateApprove) {
          this.showToast(
            'Error de validación',
            'Para aprobar la orden de traspaso se debe confirma la fecha requerida.',
            'warning',
          )

          return
        }

        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.approvingTransfer = true

        const formData = new FormData()
        formData.append('id', this.transfer.IdTransfer)

        await this.approveTransfer(formData)
        this.$swal.close()

        this.showToast(
          'Orden aprobada',
          'La orden de traspaso ha sido aprobada correctamente.',
          'success',
        )

        this.$emit('reload-transfer')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.approvingTransfer = false
      }
    },

    /**
    * Rechazo de la orden de traspaso
    *
    * @summary Muestra la ventana para ingresar el mensaje del motivo de rechazo
    */
    async onRejectTransfer() {
      this.$refs.transferRejectingCommentModal.showModal(this.transfer.IdTransfer)
    },

    /**
    * Cambio de estado a "Listo para traspaso"
    *
    * @summary Verifica la ubicación de las piezas. Si las validaciones son positivas,
    * la orden de traspaso pasa a "Listo para traspaso"
    * @exception ⁡⁢⁣⁢Error de validación en el cambio de estado
    */
    async onMakingForDeliveryTransfer() {
      try {
        // Verifica la ubicación de las piezas
        if (!this.validLocationPieces()) {
          return
        }

        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.makingForDeliveryTransfer = true

        const formData = new FormData()
        formData.append('id', this.transfer.IdTransfer)

        await this.makeForDeliveryTransfer(formData)
        this.$swal.close()

        this.showToast(
          'Estado de la orden actualizado',
          'La orden ha tomado el estado de lista para traspaso correctamente.',
          'success',
        )

        this.$emit('reload-transfer')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.makingForDeliveryTransfer = false
      }
    },

    /**
    * Cambio de estado a "en tránsito"
    *
    * @summary Verifica la ubicación de las piezas. Si las validaciones son positivas,
    * la orden de traspaso pasa a "en tránsito"
    * @exception ⁡⁢⁣⁢Error de validación en el cambio de estado
    */
    async onMakingInTransitTransfer() {
      try {
        // Verifica la ubicación de las piezas
        if (!this.validLocationPieces()) {
          return
        }

        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.makingInTransitTransfer = true

        const formData = new FormData()
        formData.append('id', this.transfer.IdTransfer)

        await this.makeInTransitTransfer(formData)
        this.$swal.close()

        this.showToast(
          'Estado de la orden actualizado',
          'La orden ha tomado el estado en tránsito correctamente.',
          'success',
        )

        this.$emit('reload-transfer')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.makingInTransitTransfer = false
      }
    },

    /**
    * Recepción de la orden de traspaso
    *
    * @summary Verifica la ubicación de las piezas. Si las validaciones son positivas,
    * se muestra la ventana para la selección de piezas recibidas.
    */
    async onReceiveTransfer() {
      // Verifica la ubicación de las piezas
      if (!this.validLocationPieces()) {
        return
      }

      this.$refs.transferReceivedProductsModal.showModal()
    },

    /**
    * Eliminación del traspaso
    *
    * @summary Atiende la eliminación de la orden de traspaso
    * @exception ⁡⁢⁣⁢Error de validación en la eliminación del traspaso
    */
    async onDeleteTransfer() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.deletingTransfer = true

        await this.deleteTransfer(this.transfer.IdTransfer)
        this.showSwalFire('¡La orden de traspaso ha sido eliminada correctamente!')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.deletingTransfer = false
      }
    },

    /**
    * Descarga de la orden de traspaso
    *
    * @summary Atiende la descarga del archivo de la orden de traspaso
    * @exception ⁡⁢⁣⁢Error en la descarga de la orden de traspaso
    */
    async onDownloadPDF() {
      try {
        this.downloadingFile = true
        await this.downloadPDFFile({ IdTransfer: this.transfer.IdTransfer, ID: this.transfer.ID })
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.downloadingFile = false
      }
    },

    /**
    * Restauración de la orden de traspaso
    *
    * @summary Limpia los cambios de la orden de traspaso y recarga
    * el estado original de ésta
    */
    async onCancelingTransferUpdate() {
      await this.clearFormData(false)
      this.$emit('reload-transfer')
    },

    /**
    * Validación de la ubicación de las piezas
    *
    * @summary Valida que existan piezas en la orden de traspaso, que todas tengan la misma
    * ubicación y que sea la misma que la de origen de la orden
    * @return {boolean} Verdadero o false
    */
    validLocationPieces() {
      // Valida que existan piezas en la orden de traspaso
      if (!this.piecesInTransfer) {
        this.showToast(
          'Error de validación',
          'No hay piezas cargadas en la orden de traspaso. No se puede validar la ubicación.',
          'warning',
        )
        return false
      }

      // Valida que todas las piezas tengan la misma ubicación
      if (!this.piecesWithSameLocation) {
        this.showToast(
          'Error de validación',
          'Las piezas no cuentan con la misma ubicación.',
          'warning',
        )
        return false
      }

      // Valida que todas las piezas tengan la misma ubicación que la del origen del traspaso
      if (!this.piecesWithSameOriginLocation) {
        this.showToast(
          'Error de validación',
          'Las piezas no cuentan con la misma ubicación de origen de la orden de traspaso.',
          'warning',
        )
        return false
      }

      return true
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },

    /**
    * Mensaje de actualización del traspaso
    *
    * @summary Muestra un mensaje de confirmación una vez que se han
    * actualizado los cambios en la orden de traspaso
    */
    showSwalFire(message) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'transfers-list' })
        }
      })
    },
  },
}
</script>
