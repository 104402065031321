<template>
  <div>
    <b-card
      no-body
      class="border border-dark shadow-none"
    >
      <b-card-body>
        <b-card-text class="d-flex justify-content-right">
          <div>
            <avatar
              :username="username"
              background-color="#0053D3"
              color="#fff"
            />
          </div>
          <div class="ml-2">
            <h4 class="font-weight-bolder">
              {{ username }}
            </h4>
            <h6>{{ comment }}</h6>
            <small class="text-muted">{{ date | moment("DD/MM/YYYY") }}</small>
          </div>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCardBody,
} from 'bootstrap-vue'

import Avatar from 'vue-avatar'

export default {
  components: {
    BCard,
    Avatar,
    BCardText,
    BCardBody,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    comment: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
}
</script>
