<template>
  <div>

    <b-card class="border border-dark shadow-none">
      <b-card-text>
        <b-row>

          <!-- #region::Title card -->
          <b-col md="12">
            <h4 class="font-weight-bolder">
              Información del traspaso
            </h4>
          </b-col>
          <!-- #endregion::Title card -->

          <!-- #region::Transfer origin -->
          <b-col
            v-if="$ability.can('read location origin', 'Transfer')"
            md="3"
          >

            <!-- #region::Origin label when edition mode is off -->
            <div
              v-if="!editionModeState || transfer.StatusText !== 'Traspaso'"
              class="d-flex"
            >
              <feather-icon
                icon="UploadIcon"
                class="mr-50 mt-1"
                size="18"
              />
              <InformationBasicCard
                title="Origen"
                :tags="[{ value: transfer.LocationOrigin, type: 'string' }]"
              />
            </div>
            <!-- #endregion::Origin label when edition mode is off -->

            <!-- #region::Origin input when edition mode is on -->
            <SelectWithValidation
              v-else
              v-model="selectedOrigin"
              vid="selectedOrigin"
              rules="required"
              name="origen"
              label="Origen"
              property="text"
              placeholder="Selecciona el origen"
              :options="filteredOriginLocations"
              :disabled="areAvailableProducts"
            />
            <!-- #endregion::Origin input when edition mode is on -->

          </b-col>
          <!-- #endregion::Transfer origin -->

          <!-- #region::Transfer destination -->
          <b-col
            v-if="$ability.can('read location destination', 'Transfer')"
            md="3"
          >

            <!-- #region::Select destination when edit mode is on -->
            <SelectWithValidation
              v-if="canEditDestinationLocation"
              v-model="selectedDestination"
              vid="selectedDestination"
              rules="required"
              name="destino"
              label="Destino"
              property="text"
              placeholder="Selecciona el destino"
              :options="filteredDestinationLocations"
            />
            <!-- #endregion::Select destination when edit mode is on -->

            <!-- #region::Destination label when edit mode is off -->
            <div
              v-else
              class="d-flex"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50 mt-1"
                size="18"
              />
              <InformationBasicCard
                title="Destino"
                :tags="[{ value: transfer.LocationDestiny, type: 'string' }]"
              />
            </div>
            <!-- #endregion::Destination label when edit mode is off -->

          </b-col>
          <!-- #endregion::Transfer destination -->

          <!-- #region::Transfer required date -->
          <b-col
            v-if="$ability.can('read required date', 'Transfer')"
            :class="[editionModeState ? 'mt-50' : '']"
            md="3"
          >

            <!-- #region::Required date picker when edit mode is on and approved date is null -->
            <DatePickerWithValidation
              v-if="editionModeState && transfer.DateApprove === null"
              v-model="selectedRequiredDate"
              vid="selectedRequiredDate"
              rules="required"
              label="Fecha requerida"
              name="fecha requerida"
              placeholder="Selecciona la fecha"
            />
            <!-- #endregion::Required date picker when edit mode is on and approved date is null -->

            <!-- #region::Required date label when edit mode is off -->
            <div
              v-else
              class="d-flex"
            >
              <feather-icon
                icon="CalendarIcon"
                class="mr-50 mt-1"
                size="18"
              />
              <div>
                <div
                  v-if="canConfirRequiredDate"
                  class="my-1"
                >
                  <h5 class="font-weight-bolder">
                    Fecha requerida
                  </h5>
                  <div class="d-flex justify-content-between">
                    <DatePickerWithValidation
                      v-model="requiredArriveDate"
                      class="w-100"
                      vid="arriveDate"
                      name="fecha requerida"
                      placeholder="Selecciona la fecha requerida"
                      :show-label="false"
                    />
                    <b-form-checkbox
                      v-model="approvedArriveDate"
                      class="ml-1 align-self-center"
                    />
                  </div>
                </div>
                <InformationBasicCard
                  v-else
                  title="Fecha requerida"
                  :tags="[{ value: transfer.DateRequired, type: 'date-d' }]"
                />
              </div>
            </div>
            <!-- #endregion::Required date label when edit mode is off -->

          </b-col>
          <!-- #endregion::Transfer required date -->

          <!-- #region::Transfer approved date -->
          <b-col
            v-if="$ability.can('read approved date', 'Transfer')"
            md="3"
          >
            <div class="d-flex">
              <feather-icon
                icon="CalendarIcon"
                class="mr-50 mt-1"
                size="18"
              />
              <InformationBasicCard
                title="Fecha aprobada"
                :tags="[{ value: transfer.DateApprove, type: 'date-d' }]"
              />
            </div>
          </b-col>
          <!-- #endregion::Transfer approved date -->

        </b-row>
      </b-card-text>
    </b-card>

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, BCard, BCardText, BFormCheckbox,
} from 'bootstrap-vue'

import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'
import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import DatePickerWithValidation from '@/components/forms/DatePickerWithValidation.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardText,
    BFormCheckbox,
    InformationBasicCard,
    SelectWithValidation,
    DatePickerWithValidation,
  },
  data() {
    return {
      requiredArriveDate: '',
      approvedArriveDate: false,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    ...mapGetters({
      // * 16/03/2023 - TAG: For edition mode
      getLocations: 'transfers/getLocations',
      getSelectedOrigin: 'transfers/getSelectedOrigin',
      getSelectedDestination: 'transfers/getSelectedDestination',
      getSelectedRequiredDate: 'transfers/getSelectedRequiredDate',
      // * 09/03/2023 - TAG: Lists information
      getTransferPieces: 'transfers/getTransferPieces',
      getTransferCircuits: 'transfers/getTransferCircuits',

      getTransfer: 'transfers/getTransfer',
      getEditionModeState: 'transfers/getEditionModeState',

      getLocationTerm: 'products/getLocationTerm',
    }),
    locations: {
      get() { return this.getLocations },
    },
    selectedOrigin: {
      get() { return this.getSelectedOrigin },
      set(value) { this.setSelectedOrigin(value) },
    },
    selectedDestination: {
      get() { return this.getSelectedDestination },
      set(value) { this.setSelectedDestination(value) },
    },
    selectedRequiredDate: {
      get() { return this.getSelectedRequiredDate },
      set(value) { this.setSelectedRequiredDate(value) },
    },
    transferPieces: {
      get() { return this.getTransferPieces },
    },
    transferCircuits: {
      get() { return this.getTransferCircuits },
    },
    editionModeState: {
      get() { return this.getEditionModeState },
    },
    transfer: {
      get() { return this.getTransfer.transfer },
    },
    locationTerm: {
      get() { return this.getLocationTerm },
      set(value) { this.setLocationTerm(value) },
    },
    filteredOriginLocations() {
      if (this.selectedDestination) {
        return this.locations.filter(location => location.text !== this.selectedDestination.text)
      }

      return this.locations
    },
    filteredDestinationLocations() {
      if (this.selectedOrigin) {
        return this.locations.filter(location => location.text !== this.selectedOrigin.text)
      }

      return this.locations
    },
    areAvailableProducts() {
      return this.transferPieces.length > 0 || this.transferCircuits.length > 0
    },
    canConfirRequiredDate() {
      return this.$ability.can('approved date transfer', 'Transferdate_approve')
          && this.transfer.LocationOrigin === this.userData.Location
          && !this.transfer.DateApprove
    },
    canEditDestinationLocation() {
      return this.editionModeState
        && (this.transfer.StatusText !== 'Aprobada'
        && this.transfer.StatusText !== 'Solicitud'
        && this.transfer.StatusText !== 'Rechazada')
    },
  },
  watch: {
    approvedArriveDate() {
      if (this.approvedArriveDate) {
        this.updateTransferApprovedDate()
      }
    },
    transferPieces() {
      if (this.transferPieces.length > 0) {
        this.selectedOrigin = { text: this.transferPieces[0].Location }
      } else if (this.transferCircuits.length === 0 && this.editionModeState === false) {
        this.selectedOrigin = ''
      }
    },
    transferCircuits() {
      if (this.transferCircuits.length > 0) {
        this.selectedOrigin = { text: this.transferCircuits[0].Location }
      } else if (this.transferPieces.length === 0 && this.editionModeState === false) {
        this.selectedOrigin = ''
      }
    },
    selectedOrigin(newSelectedOrigin) {
      if (!newSelectedOrigin) {
        this.locationTerm = ''
        return
      }

      let selectedOrigin = newSelectedOrigin

      // Verify if the selected origin is an object
      if (!(selectedOrigin instanceof Object)) {
        selectedOrigin = { text: selectedOrigin }
      }

      // Verify if the selected origin is the same as the selected destination
      if (this.selectedDestination && (selectedOrigin.text === this.selectedDestination.text)) {
        this.selectedDestination = ''
      }

      this.locationTerm = selectedOrigin.text
    },
    selectedDestination(newSelectedDestination) {
      if (!newSelectedDestination) {
        return
      }

      if (newSelectedDestination.text === this.selectedOrigin.text) {
        this.selectedOrigin = ''
      }
    },
  },
  created() {
    this.requiredArriveDate = this.transfer.DateRequired
  },
  methods: {
    ...mapActions({
      confirmTransferRequiredDate: 'transfers/confirmTransferRequiredDate',

      // * 16/03/2023 - TAG: For edition mode
      setSelectedOrigin: 'transfers/setSelectedOrigin',
      setSelectedDestination: 'transfers/setSelectedDestination',
      setSelectedRequiredDate: 'transfers/setSelectedRequiredDate',

      setLocationTerm: 'products/setLocationTerm',
    }),
    async updateTransferApprovedDate() {
      try {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()
        formData.append('id', this.transfer.IdTransfer)
        formData.append('date', this.requiredArriveDate)

        await this.confirmTransferRequiredDate(formData)

        this.showToast(
          'Fecha aprobada',
          'La fecha requerida ha sido aprobada correctamente.',
          'success',
        )

        this.$emit('reload-transfer')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
